// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import { gridContainer, gridItem } from './contact.module.css'
// Step 2: Define your component
const ContactPage = () => {
  return (
    <Layout pageTitle="Contact">
      <div class={gridContainer}>
        <div class={gridItem}><StaticImage src="../images/contact.jpg"/></div>
        <div class={gridItem}>
          <p>e: <a href='mailto:contact@alexwest.uk'>contact@alexwest.uk</a><br></br>
          i: <a href="https://www.instagram.com/alexthefabulouscat" aria-label='Instagram'>@alexthefabulouscat</a><br></br>
          u: <a href='https://unsplash.com/@paradigalla'>@paradigalla</a>
          </p>
          <br/>
          <p>Swansea, Wales</p>
          <p>Bristol, England</p>
        </div>
      </div>
    </Layout>
    
  )
}

export default ContactPage
